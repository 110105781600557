<template>
  <div class="columns is-flex-tablet">
    <div class="column">
      <b-field label="Search">
        <b-input v-model.trim="filter.query" @input="debounceQuery" placeholder="Container #, Notes" icon="magnify"></b-input>
      </b-field>
    </div>
    <div class="column">
      <b-field label="Product">
        <b-select v-model="filter.product_id" @input="applyRouteFilter" placeholder="All" icon="texture" expanded>
          <option value="">All</option>
          <option
            v-for="option in products"
            :value="option.id"
            :key="option.id">
            {{ option.code }}
          </option>
        </b-select>
      </b-field>
    </div>
    <div v-if="!hideStatus" class="column">
      <unit-status-select v-model="filter.status" @input="applyRouteFilter" :includeDefault="true" :includeDeleted="true" :allowBlank="true" ref="statusSelect" />
    </div>
    <div class="column">
      <b-field label="Fleet Status">
        <b-select v-model="filter.fleet_status" @input="applyRouteFilter" placeholder="All" icon="flag-variant-outline" expanded>
          <option value="">All</option>
          <option value="unassigned">Unassigned</option>
          <option value="rental">Rental</option>
        </b-select>
      </b-field>
    </div>
    <div class="column">
      <unit-color-select v-model="filter.color" @input="applyRouteFilter" :allowBlank="true" ref="colorSelect"/>
    </div>
    <div class="column is-1">
      <b-field label="Additional">
        <b-select v-model="filter.data" @input="applyRouteFilter" placeholder="" expanded>
          <option value=""></option>
          <option value="specialty_modification">Speciality Modification</option>
          <option value="dvt">DVT</option>
        </b-select>
      </b-field>
    </div>
    <div class="column is-1">
      <div class="field">
        <label class="label">&nbsp;</label>
        <button @click="resetFilter" class="button is-fullwidth">Reset</button>
      </div>
    </div>
  </div>
</template>

<script>
  import routeFilter from '../../mixins/route-filter'
  import UnitStatusSelect from '../../shared/components/unit-status-select'
  import UnitColorSelect from '../../shared/components/unit-color-select'

  const DEFAULTS = {
    status: 'default'
  }
  const ALLOWED = [ 'depot_id', 'query', 'product_id', 'status', 'fleet_status', 'color', 'data' ]

  export default {
    mixins: [ routeFilter ],
    components: {
      UnitStatusSelect,
      UnitColorSelect
    },
    props: {
      hideStatus: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        products: []
      }
    },
    created () {
      this.injectRouteFilter(DEFAULTS, ALLOWED)
      this.$store.dispatch('fetchRecords', [ 'product' ]).then(records => {
        this.products = records
      })
      // This is a weird one.. the two custom components aren't resetting their localValue
      // when their v-model changes..
      this.$on('filter.reset', () => {
        this.$refs.statusSelect.localValue = 'default'
        this.$refs.colorSelect.localValue = null
      })
    },
    methods: {
      debounceQuery () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.applyRouteFilter()
        }, 250)
      }
    }
  }
</script>
